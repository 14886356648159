/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import React from "react";
import { toast } from "react-toastify";
import { catchError } from "service/api";
import { apiGet } from "service/api";
import theme from "assets/theme";

import ReactLoading from "react-loading";
import { setMaskRunTimeDinheiro } from "layouts/helpers/mascaraDinheiro";
import { Card } from "@mui/material";
import MDTypography from "components/MDTypography";
import { AuthContext } from "context/AuthContext";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import { DarkMode } from "@mui/icons-material";

function Dashboard() {
  const navigate = useNavigate();
  const { usuario, logado } = React.useContext(AuthContext);

  const [load, setLoad] = React.useState(true);
  const [data, setData] = React.useState(null);

  async function loadData() {
    try {
      setLoad(true);
      if (usuario?.nivel == "A") {
        const response = await apiGet("dashboard");
        setData(response);
      } else {
        const response = await apiGet("others");
        setData(response);
      }
    } catch (error) {
      catchError(error);
    } finally {
      setLoad(false);
    }
  }

  React.useEffect(() => {
    loadData();
  }, []);

  function montaGrafico(dados, label) {
    const resposta = {
      labels: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
      datasets: {
        label: label,
        data: [
          dados["01"],
          dados["02"],
          dados["03"],
          dados["04"],
          dados["05"],
          dados["06"],
          dados["07"],
          dados["08"],
          dados["09"],
          dados["10"],
          dados["11"],
          dados["12"],
        ],
      },
    };

    return resposta;
  }

  function compareNumbers(a, b) {
    return b.total - a.total;
  }

  const colunas = [
    { Header: "dia", accessor: "dia", align: "left" },
    { Header: "total arrecadado", accessor: "arrecadado", align: "center" },
    { Header: "Funcionários no dia", accessor: "func", align: "center" },
    { Header: "seu status", accessor: "status", align: "center" },
    { Header: "Comissão do dia", accessor: "total", align: "center" },
  ];

  function renderItem(item) {
    const colorNivel =
      item.presenca == "Presente"
        ? "text"
        : item.presenca == "Folga"
        ? "info"
        : "error";

    return {
      dia: (
        <MDTypography
          component="h1"
          variant="caption"
          color={colorNivel}
          fontWeight="medium"
        >
          {item.dia}
        </MDTypography>
      ),
      arrecadado: (
        <MDTypography
          component="h1"
          variant="caption"
          color={colorNivel}
          fontWeight="medium"
        >
          {setMaskRunTimeDinheiro(parseFloat(item.total_arrecadado).toFixed(2))}
        </MDTypography>
      ),
      func: (
        <MDTypography
          component="h1"
          variant="caption"
          color={colorNivel}
          fontWeight="medium"
        >
          {item.qtd_presente}
        </MDTypography>
      ),
      status: (
        <MDTypography
          component="h1"
          variant="caption"
          color={colorNivel}
          fontWeight="medium"
        >
          {item.presenca}
        </MDTypography>
      ),
      total: (
        <MDTypography
          component="h1"
          variant="caption"
          color={colorNivel}
          fontWeight="medium"
        >
          {setMaskRunTimeDinheiro(parseFloat(item.total).toFixed(2))}
        </MDTypography>
      ),
    };
  }

  const colunasGarcon = [
    { Header: "(Nº) - Nome", accessor: "nome", align: "left" },
    { Header: "Total de comissão", accessor: "comissao", align: "center" },
    { Header: "Total de Venda", accessor: "venda", align: "center" },
  ];

  function renderItemGarcon(item, index) {
    return {
      nome: (
        <MDTypography
          component="h1"
          variant="caption"
          color={"text"}
          fontWeight="medium"
        >
          {index + 1}º - {item.nome}
        </MDTypography>
      ),
      comissao: (
        <MDTypography
          component="h1"
          variant="caption"
          color={"text"}
          fontWeight="medium"
        >
          {setMaskRunTimeDinheiro(parseFloat(item.total_comissao).toFixed(2))}
        </MDTypography>
      ),
      venda: (
        <MDTypography
          component="h1"
          variant="caption"
          color={"text"}
          fontWeight="medium"
        >
          {setMaskRunTimeDinheiro(parseFloat(item.total_venda).toFixed(2))}
        </MDTypography>
      ),
    };
  }

  function compareComissao(a, b) {
    return b.total_comissao - a.total_comissao;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {load ? (
        <div
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            marginTop: 40,
            marginBottom: 60,
          }}
        >
          <ReactLoading
            type={"spin"}
            color={theme.palette.secondary}
            height={"32px"}
            width={"32px"}
          />
        </div>
      ) : (
        <>
          {usuario?.nivel != "A" ? (
            <>
              {usuario?.e_garcon ? (
                <>
                  {data && (
                    <>
                      <MDBox py={3}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={4} lg={4}>
                            <Card>
                              <MDBox
                                display="flex"
                                justifyContent="center"
                                flexDirection="column"
                                alignItems="flexStart"
                                p={3}
                              >
                                {data.tem_meta ? (
                                  <MDButton
                                    onClick={() =>
                                      navigate("/minha-meta", {
                                        state: data.meta,
                                      })
                                    }
                                    variant="gradient"
                                    color={"success"}
                                  >
                                    Ver minha meta
                                  </MDButton>
                                ) : (
                                  <MDTypography
                                    variant="h6"
                                    gutterBottom
                                    color="secondary"
                                  >
                                    Você não possui metas.
                                  </MDTypography>
                                )}
                              </MDBox>
                            </Card>
                          </Grid>
                          <Grid item xs={12} md={8} lg={8}>
                            <Card>
                              <MDBox
                                display="flex"
                                justifyContent="center"
                                flexDirection="column"
                                alignItems="flexStart"
                                p={3}
                              >
                                <MDBox>
                                  <MDTypography variant="h5" gutterBottom>
                                    Sua comissão atual é de{" "}
                                    <span style={{ color: "#27ae60" }}>
                                      R${" "}
                                      {setMaskRunTimeDinheiro(
                                        parseFloat(
                                          data.valor_comissao_atual
                                        ).toFixed(2)
                                      )}
                                    </span>
                                  </MDTypography>
                                </MDBox>

                                <MDBox>
                                  <MDTypography
                                    variant="h6"
                                    gutterBottom
                                    color="secondary"
                                  >
                                    Período: {data.periodo}
                                  </MDTypography>
                                </MDBox>

                                <hr
                                  style={{ marginTop: 20, marginBottom: 30 }}
                                />

                                <MDBox>
                                  <MDTypography variant="h5" gutterBottom>
                                    Comissões passadas:
                                  </MDTypography>
                                </MDBox>

                                {data?.comissao_passada?.map((item) => (
                                  <MDBox>
                                    <MDTypography variant="h6" gutterBottom>
                                      {item.mes} - R${" "}
                                      {setMaskRunTimeDinheiro(
                                        parseFloat(item.valor).toFixed(2)
                                      )}
                                    </MDTypography>
                                  </MDBox>
                                ))}

                                {/*
                                <hr
                                  style={{ marginTop: 20, marginBottom: 30 }}
                                />

                                <MDBox>
                                  <MDTypography
                                    variant="h6"
                                    gutterBottom
                                    color="secondary"
                                  >
                                    Ranking do mês atual (Comissão):
                                  </MDTypography>
                                </MDBox>

                                <DataTable
                                  table={{
                                    columns: colunasGarcon,
                                    rows: data.ranking_atual
                                      .sort(compareComissao)
                                      .map((item, index) =>
                                        renderItemGarcon(item, index)
                                      ),
                                  }}
                                  isSorted={false}
                                  entriesPerPage={false}
                                  showTotalEntries={false}
                                  noEndBorder
                                />
                                      */}
                              </MDBox>
                            </Card>
                          </Grid>
                        </Grid>
                      </MDBox>
                    </>
                  )}
                </>
              ) : (
                <>
                  {data && (
                    <>
                      <MDBox py={3}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={12} lg={12}>
                            <Card>
                              <MDBox
                                display="flex"
                                justifyContent="center"
                                flexDirection="column"
                                alignItems="flexStart"
                                p={3}
                              >
                                <MDBox>
                                  <MDTypography variant="h5" gutterBottom>
                                    Sua estimativa de comissão atual é de{" "}
                                    <span style={{ color: "#27ae60" }}>
                                      R${" "}
                                      {setMaskRunTimeDinheiro(
                                        parseFloat(
                                          data.valor_comissao_atual
                                        ).toFixed(2)
                                      )}
                                    </span>
                                  </MDTypography>
                                </MDBox>
                                <MDBox>
                                  <MDTypography
                                    variant="h6"
                                    gutterBottom
                                    color="secondary"
                                  >
                                    Total de comissão para dividir: R${" "}
                                    {setMaskRunTimeDinheiro(
                                      parseFloat(
                                        data.total_comissao_60
                                      ).toFixed(2)
                                    )}
                                  </MDTypography>
                                </MDBox>
                                <MDBox>
                                  <MDTypography
                                    variant="h6"
                                    gutterBottom
                                    color="secondary"
                                  >
                                    Período: {data.periodo}
                                  </MDTypography>
                                </MDBox>

                                <hr
                                  style={{ marginTop: 20, marginBottom: 30 }}
                                />

                                <MDBox>
                                  <MDTypography
                                    variant="h6"
                                    gutterBottom
                                    color="secondary"
                                  >
                                    Detalhamento da comissão atual:
                                  </MDTypography>
                                </MDBox>

                                <DataTable
                                  table={{
                                    columns: colunas,
                                    rows: data.detalhe_comissao_atual.map(
                                      (item) => renderItem(item)
                                    ),
                                  }}
                                  isSorted={false}
                                  entriesPerPage={false}
                                  showTotalEntries={false}
                                  noEndBorder
                                />
                              </MDBox>
                            </Card>
                          </Grid>

                          {/* <Grid item xs={12} md={4} lg={4}>
                            <Card>
                              <MDBox
                                display="flex"
                                justifyContent="center"
                                flexDirection="column"
                                alignItems="flexStart"
                                p={3}
                              >
                                <MDBox>
                                  <MDTypography variant="h5" gutterBottom>
                                    Comissões passadas:
                                  </MDTypography>
                                </MDBox>

                                {data?.comissao_passada?.map((item) => (
                                  <MDBox>
                                    <MDTypography variant="h6" gutterBottom>
                                      {item.mes} - R${" "}
                                      {setMaskRunTimeDinheiro(
                                        parseFloat(item.valor).toFixed(2)
                                      )}
                                    </MDTypography>
                                  </MDBox>
                                ))}
                              </MDBox>
                            </Card>
                          </Grid>*/}
                        </Grid>
                      </MDBox>
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {data && (
                <MDBox py={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={4}>
                      <MDBox mb={1.5}>
                        <ComplexStatisticsCard
                          color="primary"
                          icon="person"
                          title="Vendedores"
                          count={data?.qtd_vendedor}
                          percentage={{
                            color: "success",
                            amount: "",
                            label: "Atualizado",
                          }}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <MDBox mb={1.5}>
                        <ComplexStatisticsCard
                          icon="leaderboard"
                          title="Total em Vendas (Mês atual)"
                          count={
                            "R$ " +
                            setMaskRunTimeDinheiro(
                              parseFloat(data?.venda_mes_atual).toFixed(2)
                            )
                          }
                          percentage={{
                            color:
                              data?.venda_comparacao_mes_atual >= 0
                                ? "success"
                                : "error",
                            amount:
                              data?.venda_comparacao_mes_atual >= 0
                                ? "+" +
                                  setMaskRunTimeDinheiro(
                                    parseFloat(
                                      data?.venda_comparacao_mes_atual
                                    ).toFixed(2)
                                  ) +
                                  "%"
                                : "-" +
                                  setMaskRunTimeDinheiro(
                                    parseFloat(
                                      data?.venda_comparacao_mes_atual
                                    ).toFixed(2)
                                  ) +
                                  "%",
                            label: "do que no mês passado.",
                          }}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <MDBox mb={1.5}>
                        <ComplexStatisticsCard
                          color="success"
                          icon="attach_money"
                          title="Total em Comissão (Mês atual)"
                          count={
                            "R$ " +
                            setMaskRunTimeDinheiro(
                              parseFloat(data?.comissao_mes_atual).toFixed(2)
                            )
                          }
                          percentage={{
                            color:
                              data?.comissao_comparacao_mes_atual >= 0
                                ? "success"
                                : "error",
                            amount:
                              data?.comissao_comparacao_mes_atual >= 0
                                ? "+" +
                                  setMaskRunTimeDinheiro(
                                    parseFloat(
                                      data?.comissao_comparacao_mes_atual
                                    ).toFixed(2)
                                  ) +
                                  "%"
                                : "-" +
                                  setMaskRunTimeDinheiro(
                                    parseFloat(
                                      data?.comissao_comparacao_mes_atual
                                    ).toFixed(2)
                                  ) +
                                  "%",
                            label: "do que no mês passado.",
                          }}
                        />
                      </MDBox>
                    </Grid>
                  </Grid>
                  <MDBox mt={4.5}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6} lg={6}>
                        <MDBox mb={3}>
                          <ReportsLineChart
                            color="info"
                            title="Comparação vendas (Ano)"
                            date="atualizado agora"
                            chart={montaGrafico(data?.venda_ano, "Vendas")}
                          />
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <MDBox mb={3}>
                          <ReportsLineChart
                            color="success"
                            title="Comparação comissão (Ano)"
                            date="atualizado agora"
                            chart={montaGrafico(data?.comissa_ano, "Comissão")}
                          />
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                  <MDBox>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6} lg={5}>
                        <Card>
                          <MDBox
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            p={3}
                          >
                            <MDBox>
                              <MDTypography variant="h6" gutterBottom>
                                Ranking de vendas (Mês atual):
                              </MDTypography>
                            </MDBox>
                          </MDBox>
                          <MDBox>
                            {data?.rankingVenda.length == 0 && (
                              <MDBox
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                pl={3}
                                pr={3}
                                pb={3}
                              >
                                <MDTypography
                                  variant="body2"
                                  fontWeight="regular"
                                  color="dark"
                                >
                                  Nenhum colaborador possui venda no mês atual.
                                </MDTypography>
                              </MDBox>
                            )}

                            {data?.rankingVenda
                              .sort(compareNumbers)
                              .map((item, index) => (
                                <MDBox
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  pl={3}
                                  pr={3}
                                  pb={3}
                                >
                                  <MDTypography
                                    variant="body2"
                                    fontWeight="regular"
                                    color="dark"
                                  >
                                    {index + 1}º - {item.nome}
                                  </MDTypography>
                                  <MDTypography
                                    variant="body2"
                                    fontWeight="bold"
                                    color="dark"
                                  >
                                    R${" "}
                                    {setMaskRunTimeDinheiro(
                                      parseFloat(item.total).toFixed(2)
                                    )}
                                  </MDTypography>
                                </MDBox>
                              ))}
                          </MDBox>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={6} lg={5}>
                        <Card>
                          <MDBox
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            p={3}
                          >
                            <MDBox>
                              <MDTypography variant="h6" gutterBottom>
                                Ranking de comissão (Mês atual):
                              </MDTypography>
                            </MDBox>
                          </MDBox>
                          <MDBox>
                            {data?.ranking.length == 0 && (
                              <MDBox
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                pl={3}
                                pr={3}
                                pb={3}
                              >
                                <MDTypography
                                  variant="body2"
                                  fontWeight="regular"
                                  color="dark"
                                >
                                  Nenhum colaborador possui comissão no mês
                                  atual.
                                </MDTypography>
                              </MDBox>
                            )}

                            {data?.ranking
                              .sort(compareNumbers)
                              .map((item, index) => (
                                <MDBox
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  pl={3}
                                  pr={3}
                                  pb={3}
                                >
                                  <MDTypography
                                    variant="body2"
                                    fontWeight="regular"
                                    color="dark"
                                  >
                                    {index + 1}º - {item.nome}
                                  </MDTypography>
                                  <MDTypography
                                    variant="body2"
                                    fontWeight="bold"
                                    color="dark"
                                  >
                                    R${" "}
                                    {setMaskRunTimeDinheiro(
                                      parseFloat(item.total).toFixed(2)
                                    )}
                                  </MDTypography>
                                </MDBox>
                              ))}
                          </MDBox>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={6} lg={2}>
                        <Card>
                          <MDBox
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            p={3}
                          >
                            <MDBox>
                              <MDTypography variant="h6" gutterBottom>
                                Folgas do dia:
                              </MDTypography>
                            </MDBox>
                          </MDBox>
                          <MDBox>
                            {data?.folgas.length == 0 && (
                              <MDBox
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                pl={3}
                                pr={3}
                                pb={3}
                              >
                                <MDTypography
                                  variant="body2"
                                  fontWeight="regular"
                                  color="dark"
                                >
                                  Hoje não tem ninguém de folga.
                                </MDTypography>
                              </MDBox>
                            )}

                            {data?.folgas.map((item) => (
                              <MDBox
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                pl={3}
                                pr={3}
                                pb={3}
                              >
                                <MDTypography
                                  variant="body2"
                                  fontWeight="regular"
                                  color="dark"
                                >
                                  {item}
                                </MDTypography>
                              </MDBox>
                            ))}
                          </MDBox>
                        </Card>
                      </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>
              )}
            </>
          )}
        </>
      )}
    </DashboardLayout>
  );
}

export default Dashboard;
