/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";

// @mui icons

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import constants from "constants";
import React, { useState } from "react";
import { AuthContext } from "context/AuthContext";
import { apiPost } from "service/api";

import { toast } from "react-toastify";
import { catchError } from "service/api";
import { Navigate, useNavigate } from "react-router-dom";

function Basic() {
  const navigate = useNavigate();

  const { logar } = React.useContext(AuthContext);
  const [load, setLoad] = useState(false);

  const [acesso, setAcesso] = React.useState("");
  const [senha, setSenha] = React.useState("");

  async function login() {
    if (!acesso) {
      toast.error("Informe um acesso.");
      return;
    }

    if (!senha) {
      toast.error("Informe uma senha.");
      return;
    }

    try {
      setLoad(true);

      const response = await apiPost("login", {
        acesso: acesso,
        senha: senha,
      });

      logar(response, response.token);
      navigate("/dashboard", { replace: true });
    } catch (e) {
      catchError(e);
    } finally {
      setLoad(false);
    }
  }

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            {constants.NomeProduto}
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Acesso"
                fullWidth
                onChange={(e) => setAcesso(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Senha"
                fullWidth
                onChange={(e) => setSenha(e.target.value)}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <div onClick={login}>
                <MDButton variant="gradient" color="info" fullWidth>
                  {load ? "Entrando" : "Entrar"}
                </MDButton>
              </div>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
