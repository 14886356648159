/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import ReactLoading from "react-loading";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import MDButton from "components/MDButton";
import theme from "assets/theme";
import React, { useState } from "react";
import { catchError } from "service/api";
import { apiGet } from "service/api";
import MDBadge from "components/MDBadge";
import { apiDelete } from "service/api";
import { toast } from "react-toastify";
import { FormControl, Icon, InputLabel, MenuItem, Select } from "@mui/material";
import { DarkMode } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import { format, parseISO, subYears, addYears } from "date-fns";
import {
  gerarComissaRelatorio,
  gerarFaltasRelatorio,
  gerarMetaRelatorio,
} from "./rels";
import MDInput from "components/MDInput";
import { setMaskRunTimeDinheiro } from "layouts/helpers/mascaraDinheiro";

export function Relatorio() {
  const navigate = useNavigate();

  const [load, setLoad] = React.useState(false);
  const [loadExclusao, setLoadExclusao] = React.useState(null);
  const [data, setData] = React.useState([]);

  const [filtros, setFiltros] = React.useState({});

  const filtrosAno = React.useMemo(() => {
    let dataAtual = format(new Date(), "yyyy");

    dataAtual = parseInt(dataAtual);
    dataAtual -= 5;

    const aux = [];

    for (let i = 0; i <= 10; i++) {
      aux.push(dataAtual);
      dataAtual = dataAtual + 1;
    }

    return aux;
  }, []);

  const [dataFilter, setDataFilter] = React.useState(
    parseInt(format(new Date(), "yyyy"))
  );

  const [tipoRelatorio, setTipoRelatorio] = React.useState(null);

  async function getData() {
    try {
      setLoad(true);

      if (tipoRelatorio === "faltas") {
        if (!filtros?.mes || !filtros?.ano) {
          toast.error("Informa os filtros necessários.");
          return;
        }

        const response = await apiGet(
          `relatorio/falta/${filtros?.mes}/${filtros?.ano}`
        );

        gerarFaltasRelatorio(response, setUrl);
      }

      if (tipoRelatorio === "comissao") {
        if (!filtros?.dataInicial || !filtros?.dataFinal) {
          toast.error("Informa os filtros necessários.");
          return;
        }

        const response = await apiGet(
          `relatorio/comissao?dataInicial=${filtros?.dataInicial}&dataFinal=${filtros?.dataFinal}`
        );

        gerarComissaRelatorio(response, setUrl);
      }

      if (tipoRelatorio === "metas") {
        if (!filtros?.idMeta) {
          toast.error("Escolhe uma meta.");
          return;
        }

        const response = await apiGet(`relatorio/meta/${filtros?.idMeta}`);
        gerarMetaRelatorio(response, setUrl);
      }
    } catch (e) {
      catchError(e);
    } finally {
      setLoad(false);
    }
  }

  const [loadMeta, setLoadMeta] = useState(false);
  const [metas, setMetas] = useState([]);

  async function getMetas(ano) {
    try {
      setLoadMeta(true);
      const response = await apiGet(`objective?filterYear=${ano}`);
      setMetas(response);
    } catch (e) {
      catchError(e);
    } finally {
      setLoadMeta(false);
    }
  }

  const [url, setUrl] = useState(null);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Relatórios
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <div
                  style={{
                    width: "100%",
                    paddingLeft: 18,
                    paddingRight: 18,
                    paddingBottom: 20,
                    gap: 16,
                    display: "flex",
                  }}
                >
                  <FormControl style={{ width: "50%", maxWidth: 200 }}>
                    <InputLabel id="demo-simple-select-label">
                      Relatórios
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Relatórios"
                      size="medium"
                      style={{ height: 40, width: "100%" }}
                      value={tipoRelatorio}
                      onChange={(e) => {
                        setUrl(null);
                        setTipoRelatorio(e.target.value);
                      }}
                    >
                      <MenuItem value={"faltas"}>Faltas</MenuItem>
                      <MenuItem value={"comissao"}>Comissão</MenuItem>
                      <MenuItem value={"metas"}>Metas</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                {tipoRelatorio === "metas" && (
                  <div
                    style={{
                      width: "100%",
                      paddingLeft: 18,
                      paddingRight: 18,
                      paddingBottom: 20,
                      gap: 16,
                      display: "flex",
                    }}
                  >
                    <FormControl style={{ width: "50%", maxWidth: 200 }}>
                      <InputLabel id="demo-simple-select-label">Ano</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Ano"
                        size="medium"
                        style={{ height: 40, width: "100%" }}
                        value={filtros?.ano}
                        onChange={(e) => {
                          setFiltros((prev) => {
                            const obj = prev;
                            return {
                              ...obj,
                              idMeta: null,
                              ano: e.target.value,
                            };
                          });

                          getMetas(e.target.value);
                        }}
                      >
                        {filtrosAno.map((ano) => (
                          <MenuItem value={ano}>{ano}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {loadMeta ? (
                      <span style={{ fontSize: 14 }}>Carregando metas</span>
                    ) : (
                      <>
                        {metas.length == 0 ? (
                          <span style={{ fontSize: 14 }}>
                            Nenhuma meta encontrada.
                          </span>
                        ) : (
                          <FormControl style={{ width: "50%", maxWidth: 200 }}>
                            <InputLabel id="demo-simple-select-label">
                              Metas
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Metas"
                              size="medium"
                              style={{ height: 40, width: "100%" }}
                              value={filtros?.idMeta}
                              onChange={(e) => {
                                setFiltros((prev) => {
                                  const obj = prev;
                                  return { ...obj, idMeta: e.target.value };
                                });
                              }}
                            >
                              {metas.map((meta) => (
                                <MenuItem value={meta.id}>{meta.nome}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      </>
                    )}

                    <MDButton
                      variant="gradient"
                      color="success"
                      onClick={getData}
                    >
                      Aplicar
                    </MDButton>
                  </div>
                )}

                {tipoRelatorio === "faltas" && (
                  <div
                    style={{
                      width: "100%",
                      paddingLeft: 18,
                      paddingRight: 18,
                      paddingBottom: 20,
                      gap: 16,
                      display: "flex",
                    }}
                  >
                    <FormControl style={{ width: "50%", maxWidth: 200 }}>
                      <InputLabel id="demo-simple-select-label">Mês</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Mês"
                        size="medium"
                        style={{ height: 40, width: "100%" }}
                        value={filtros?.mes}
                        onChange={(e) =>
                          setFiltros((prev) => {
                            const obj = prev;
                            return { ...obj, mes: e.target.value };
                          })
                        }
                      >
                        <MenuItem value={"01"}>Janeiro</MenuItem>
                        <MenuItem value={"02"}>Fevereiro</MenuItem>
                        <MenuItem value={"03"}>Março</MenuItem>
                        <MenuItem value={"04"}>Abril</MenuItem>
                        <MenuItem value={"05"}>Maio</MenuItem>
                        <MenuItem value={"06"}>Junho</MenuItem>
                        <MenuItem value={"07"}>Julho</MenuItem>
                        <MenuItem value={"08"}>Agosto</MenuItem>
                        <MenuItem value={"09"}>Setembro</MenuItem>
                        <MenuItem value={"10"}>Outubro</MenuItem>
                        <MenuItem value={"11"}>Novembro</MenuItem>
                        <MenuItem value={"12"}>Dezembro</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl style={{ width: "50%", maxWidth: 200 }}>
                      <InputLabel id="demo-simple-select-label">Ano</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Ano"
                        size="medium"
                        style={{ height: 40, width: "100%" }}
                        value={filtros?.ano}
                        onChange={(e) =>
                          setFiltros((prev) => {
                            const obj = prev;
                            return { ...obj, ano: e.target.value };
                          })
                        }
                      >
                        {filtrosAno.map((ano) => (
                          <MenuItem value={ano}>{ano}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <MDButton
                      variant="gradient"
                      color="success"
                      onClick={getData}
                    >
                      Aplicar
                    </MDButton>
                  </div>
                )}

                {tipoRelatorio === "comissao" && (
                  <div
                    style={{
                      width: "100%",
                      paddingLeft: 18,
                      paddingRight: 18,
                      paddingBottom: 20,
                      gap: 16,
                      display: "flex",
                    }}
                  >
                    <FormControl style={{ width: "50%", maxWidth: 200 }}>
                      <MDInput
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Data Inicial"
                        type="date"
                        size="medium"
                        style={{ height: 40, width: "100%" }}
                        value={filtros?.dataInicial}
                        onChange={(e) =>
                          setFiltros((prev) => {
                            const obj = prev;
                            return { ...obj, dataInicial: e.target.value };
                          })
                        }
                      />
                    </FormControl>

                    <FormControl style={{ width: "50%", maxWidth: 200 }}>
                      <MDInput
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Date Final"
                        size="medium"
                        type="date"
                        style={{ height: 40, width: "100%" }}
                        value={filtros?.dataFinal}
                        onChange={(e) =>
                          setFiltros((prev) => {
                            const obj = prev;
                            return { ...obj, dataFinal: e.target.value };
                          })
                        }
                      />
                    </FormControl>

                    <MDButton
                      variant="gradient"
                      color="success"
                      onClick={getData}
                    >
                      Aplicar
                    </MDButton>
                  </div>
                )}

                {load ? (
                  <div
                    style={{
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      marginTop: 40,
                      marginBottom: 60,
                    }}
                  >
                    <ReactLoading
                      type={"spin"}
                      color={theme.palette.secondary}
                      height={"32px"}
                      width={"32px"}
                    />
                  </div>
                ) : (
                  <>
                    {url !== null && (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          paddingLeft: 40,
                          paddingRight: 40,
                        }}
                      >
                        <iframe
                          height="800"
                          width="100%"
                          src={url}
                          title="description"
                        ></iframe>
                      </div>
                    )}
                  </>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
