/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

import ReactLoading from "react-loading";

// Data
import MDButton from "components/MDButton";
import theme from "assets/theme";
import React from "react";
import { catchError } from "service/api";
import { apiGet } from "service/api";
import MDBadge from "components/MDBadge";
import { apiDelete } from "service/api";
import { toast } from "react-toastify";
import { Divider, Icon, MenuItem, Select, Switch } from "@mui/material";
import { DarkMode } from "@mui/icons-material";
import MDInput from "components/MDInput";
import { useLocation, useNavigate } from "react-router-dom";
import { apiPost } from "service/api";
import { apiPut } from "service/api";

export function ColaboradorCadastro() {
  const navigate = useNavigate();
  const location = useLocation();

  const [load, setLoad] = React.useState(false);

  const [nome, setNome] = React.useState("");
  const [acesso, setAcesso] = React.useState("");
  const [senha, setSenha] = React.useState("");
  const [nivel, setNivel] = React.useState(false);
  const [eGarcon, setEGarcon] = React.useState(false);
  const [comissao, setComissao] = React.useState(true);
  const [seg, setSeg] = React.useState(false);
  const [ter, setTer] = React.useState(false);
  const [qua, setQua] = React.useState(false);
  const [qui, setQui] = React.useState(false);
  const [sex, setSex] = React.useState(false);
  const [sab, setSab] = React.useState(false);
  const [dom, setDom] = React.useState(false);

  React.useEffect(() => {
    if (location.state != null) {
      const item = location.state;

      setNome(item.nome);
      setAcesso(item.acesso);
      setNivel(item.nivel == "A" ? true : false);
      setEGarcon(!!item.e_garcon);
      setComissao(!!item.tem_comissao);

      let seg = false;
      let ter = false;
      let qua = false;
      let qui = false;
      let sex = false;
      let sab = false;
      let dom = false;

      const folgas = item.folgas.split(",");
      folgas.forEach((folga) => {
        if (folga == "seg") {
          seg = true;
        }

        if (folga == "ter") {
          ter = true;
        }

        if (folga == "qua") {
          qua = true;
        }

        if (folga == "qui") {
          qui = true;
        }

        if (folga == "sex") {
          sex = true;
        }

        if (folga == "sab") {
          sab = true;
        }

        if (folga == "dom") {
          dom = true;
        }
      });

      setSeg(seg);
      setTer(ter);
      setQua(qua);
      setQui(qui);
      setSex(sex);
      setSab(sab);
      setDom(dom);
    }
  }, []);

  async function salvar() {
    if (!nome) {
      toast.error("Insira um nome.");
      return;
    }

    if (location.state == null) {
      if (acesso && !senha) {
        toast.error("Insira uma senha.");
        return;
      }
    }

    if (!acesso && senha) {
      toast.error("Insira um acesso.");
      return;
    }

    try {
      setLoad(true);

      const data = {
        nome: nome,
        nivel: nivel ? "A" : "O",
        acesso: !!acesso ? acesso : null,
        senha: !!senha ? senha : null,
        e_garcon: eGarcon,
        tem_comissao: comissao,
        segunda: seg ? "t" : "f",
        terca: ter ? "t" : "f",
        quarta: qua ? "t" : "f",
        quinta: qui ? "t" : "f",
        sexta: sex ? "t" : "f",
        sabado: sab ? "t" : "f",
        domingo: dom ? "t" : "f",
      };

      if (location.state == null) {
        await apiPost("/collaborator", data);
        toast.success("Registro salvo.");
      } else {
        await apiPut(`collaborator/${location.state?.id}`, data);
        toast.success("Registro editado.");
      }

      navigate("/colaborador");
    } catch (e) {
      catchError(e);
    } finally {
      setLoad(false);
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDButton
        variant="gradient"
        color="light"
        onClick={() => navigate("/colaborador")}
      >
        Voltar
      </MDButton>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  {location.state == null ? "Cadastrar" : "Editar"} Colaborador
                </MDTypography>

                <MDButton variant="gradient" color="dark" onClick={salvar}>
                  {load ? "Salvando" : "Salvar"}
                </MDButton>
              </MDBox>

              <MDBox p={3}>
                <MDBox mb={2} width={"100%"} display="flex" style={{ gap: 16 }}>
                  <MDInput
                    label="Nome *"
                    fullWidth
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                  />
                </MDBox>

                <MDBox mb={4} width={"100%"} display="flex" style={{ gap: 16 }}>
                  <MDInput
                    label="Acesso"
                    fullWidth
                    value={acesso}
                    onChange={(e) => {
                      let textoAcesso = e.target.value.trim();
                      const regex = /\s/gi;
                      textoAcesso = textoAcesso.replaceAll(regex, "");
                      textoAcesso = textoAcesso.toLocaleLowerCase();

                      setAcesso(textoAcesso);
                    }}
                  />
                  <MDInput
                    type="password"
                    label="Senha"
                    fullWidth
                    value={senha}
                    onChange={(e) => setSenha(e.target.value)}
                  />
                </MDBox>

                <Divider />

                <MDTypography variant="body2" fontWeight="regular" color="dark">
                  Configurações:
                </MDTypography>

                <MDBox display="flex" alignItems="center">
                  <MDBox mt={0.5}>
                    <Switch checked={nivel} onChange={() => setNivel(!nivel)} />
                  </MDBox>
                  <MDBox mr={0.5}>
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Administrador?
                    </MDTypography>
                  </MDBox>
                </MDBox>

                <MDBox display="flex" alignItems="center">
                  <MDBox mt={0.5}>
                    <Switch
                      checked={eGarcon}
                      onChange={() => setEGarcon(!eGarcon)}
                    />
                  </MDBox>
                  <MDBox mr={0.5}>
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      É garçom?
                    </MDTypography>
                  </MDBox>
                </MDBox>

                <MDBox display="flex" alignItems="center">
                  <MDBox mt={0.5}>
                    <Switch
                      checked={comissao}
                      onChange={() => setComissao(!comissao)}
                    />
                  </MDBox>
                  <MDBox mr={0.5}>
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Recebe comissão?
                    </MDTypography>
                  </MDBox>
                </MDBox>

                <Divider />

                <MDTypography variant="body2" fontWeight="regular" color="dark">
                  Folgas:
                </MDTypography>

                <MDBox display="flex" alignItems="center">
                  <MDBox mt={0.5}>
                    <Switch checked={seg} onChange={() => setSeg(!seg)} />
                  </MDBox>
                  <MDBox mr={0.5}>
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Segunda-Feira
                    </MDTypography>
                  </MDBox>
                </MDBox>

                <MDBox display="flex" alignItems="center">
                  <MDBox mt={0.5}>
                    <Switch checked={ter} onChange={() => setTer(!ter)} />
                  </MDBox>
                  <MDBox mr={0.5}>
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Terça-Feira
                    </MDTypography>
                  </MDBox>
                </MDBox>

                <MDBox display="flex" alignItems="center">
                  <MDBox mt={0.5}>
                    <Switch checked={qua} onChange={() => setQua(!qua)} />
                  </MDBox>
                  <MDBox mr={0.5}>
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Quarta-Feira
                    </MDTypography>
                  </MDBox>
                </MDBox>

                <MDBox display="flex" alignItems="center">
                  <MDBox mt={0.5}>
                    <Switch checked={qui} onChange={() => setQui(!qui)} />
                  </MDBox>
                  <MDBox mr={0.5}>
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Quinta-Feira
                    </MDTypography>
                  </MDBox>
                </MDBox>

                <MDBox display="flex" alignItems="center">
                  <MDBox mt={0.5}>
                    <Switch checked={sex} onChange={() => setSex(!sex)} />
                  </MDBox>
                  <MDBox mr={0.5}>
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Sexta-Feira
                    </MDTypography>
                  </MDBox>
                </MDBox>

                <MDBox display="flex" alignItems="center">
                  <MDBox mt={0.5}>
                    <Switch checked={sab} onChange={() => setSab(!sab)} />
                  </MDBox>
                  <MDBox mr={0.5}>
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Sábado
                    </MDTypography>
                  </MDBox>
                </MDBox>

                <MDBox display="flex" alignItems="center">
                  <MDBox mt={0.5}>
                    <Switch checked={dom} onChange={() => setDom(!dom)} />
                  </MDBox>
                  <MDBox mr={0.5}>
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Domingo
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
