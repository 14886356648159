import styled from "styled-components";

export const ContainerProgressoSimples = styled.div`
  @media (max-width: 600px) {
    display: none;
  }

  height: 30px;
  width: 300px;
  background: #ddd;
  border: 1px solid #ccc;
  border-radius: 4px;

  position: relative;
  margin-right: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 12px;
    color: #3e3e3e;
    z-index: 999;
    font-weight: bold;
  }
`;

export const ProgressoSimples = styled.div`
  position: absolute;
  height: 30px;
  width: ${({ progresso }) => {
    if (progresso < 0) {
      return 0;
    }

    if (progresso > 100) {
      return 300;
    }

    return progresso * 3;
  }}px;
  background: ${({ cor }) => cor};
  border-radius: 4px;
  position: absolute;
  left: 0;
`;

export const ContainerInfo = styled.div`
  width: 100%;
  padding-bottom: 20px;

  hr {
    margin-bottom: 20px;
  }
`;

export const ContainerGraficoProgresso = styled.div`
  @media screen and (max-width: 600px) {
    height: 60px;
  }

  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 80px;
`;

export const WrapperProgresso = styled.div`
  @media screen and (max-width: 600px) {
    height: 60px;
  }

  width: 100%;
  height: 80px;
  position: relative;
  background: #ddd;
  border: 1px solid #ccc;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 12px;
    color: #3e3e3e;
    font-weight: bold;
    z-index: 998;
    text-align: center;
  }
`;

export const ContaienrProgresso = styled.div`
  width: 100%;
  height: 80px;
  padding: 0px 80px;
`;

export const ContainerDivisor = styled.div`
  height: 80px;
  width: 160px;
  margin-left: calc(${({ progresso }) => progresso}% + (-80px));
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  z-index: 999;
`;

export const ContainerValorMinimo = styled.div`
  span {
    font-size: 12px;
    height: 20px;
    color: #e67e22;
  }
`;

export const SeparadorMinimo = styled.div`
  height: 140px;
  width: 1px;
  border-left: 1px dashed #e67e22;
  position: absolute;
  top: 20px;
`;

export const ContainerDivisoDesejado = styled.div`
  height: 80px;
  width: 160px;
  margin-right: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  position: relative;
`;

export const ContainerValorDesejado = styled.div`
  display: flex;
  justify-content: flex-end;

  span {
    font-size: 12px;
    height: 20px;
    color: #27ae60;
  }
`;

export const SeparadorDesejado = styled.div`
  height: 140px;
  width: 1px;
  border-left: 1px dashed #27ae60;
  position: absolute;
  bottom: 20px;
`;

export const WrapperMeuProgresso = styled.div`
  @media screen and (max-width: 600px) {
    height: 60px;
  }

  width: ${({ progresso }) => {
    if (progresso < 0) {
      return 0;
    }

    if (progresso > 100) {
      return 100;
    }

    return progresso;
  }}%;
  z-index: 997;
  height: 80px;
  position: absolute;
  left: 0;
  background: ${({ cor }) => cor};
  border-radius: 8px;
`;
