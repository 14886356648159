import React from "react";

export const AuthContext = React.createContext({});

export function AuthProvider({ children }) {
  const [usuario, setUsuario] = React.useState({});
  const [token, setToken] = React.useState(null);

  const [logado, setLogado] = React.useState(false);
  const [load, setLoad] = React.useState(true);

  React.useEffect(() => {
    const valor = sessionStorage.getItem("metahoo@1.0.0-is-authenticated");

    if (valor == "T") {
      const user = sessionStorage.getItem("metahoo@1.0.0-usuario");
      const tk = sessionStorage.getItem("metahoo@1.0.0-token");

      if (user != undefined && user != "" && user != null) {
        setUsuario(JSON.parse(user));
      }

      if (tk != undefined && tk != "" && tk != null) {
        setToken(tk);
      }

      setLogado(true);
      setLoad(false);
    } else {
      sessionStorage.setItem("metahoo@1.0.0-usuario", "");
      sessionStorage.setItem("metahoo@1.0.0-token", "");

      setLogado(false);
      setLoad(false);
    }
  }, []);

  function logar(user, tk) {
    sessionStorage.setItem("metahoo@1.0.0-is-authenticated", "T");
    sessionStorage.setItem("metahoo@1.0.0-usuario", JSON.stringify(user));
    sessionStorage.setItem("metahoo@1.0.0-token", tk);

    setUsuario(user);
    setToken(tk);
  }

  function sair() {
    sessionStorage.setItem("metahoo@1.0.0-is-authenticated", "F");
    sessionStorage.setItem("metahoo@1.0.0-usuario", "");
    sessionStorage.setItem("metahoo@1.0.0-token", "");
  }

  return (
    <AuthContext.Provider value={{ logado, logar, load, sair, usuario, token }}>
      {children}
    </AuthContext.Provider>
  );
}
