/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

import ReactLoading from "react-loading";

// Data
import MDButton from "components/MDButton";
import theme from "assets/theme";
import React from "react";
import { catchError } from "service/api";
import { apiGet } from "service/api";
import MDBadge from "components/MDBadge";
import { apiDelete } from "service/api";
import { toast } from "react-toastify";
import { Divider, Icon, MenuItem, Select, Slider, Switch } from "@mui/material";
import { DarkMode } from "@mui/icons-material";
import MDInput from "components/MDInput";
import { useLocation, useNavigate } from "react-router-dom";
import { apiPost } from "service/api";
import { apiPut } from "service/api";
import { removeMaskDinheiro } from "layouts/helpers/mascaraDinheiro";
import { setMaskRunTimeDinheiro } from "layouts/helpers/mascaraDinheiro";

export function ConfigGeral() {
  const navigate = useNavigate();

  const [load, setLoad] = React.useState(false);
  const [loadContainer, setLoadContainer] = React.useState(true);

  const [txDesconto, setTxDesconto] = React.useState("");
  const [txBruta, setTxBruta] = React.useState(40);

  async function getData() {
    try {
      setLoadContainer(true);

      const response = await apiGet("config");
      setTxBruta(parseInt(response.taxa_garcon));

      if (!!response.taxa_descontos) {
        if (parseFloat(response.taxa_descontos) > 0) {
          setTxDesconto(
            setMaskRunTimeDinheiro(
              parseFloat(response.taxa_descontos).toFixed(2)
            )
          );
        }
      }
    } catch (e) {
      catchError(e);
      navigate("/dashboard");
    } finally {
      setLoadContainer(false);
    }
  }

  React.useEffect(() => {
    getData();
  }, []);

  async function salvar() {
    if (!!txDesconto) {
      if (removeMaskDinheiro(txDesconto) > 100) {
        toast.error("O desconto não pode ser maior que 100%");
        return;
      }
    }

    try {
      setLoad(true);

      const data = {
        taxa_garcon: txBruta,
        taxa_cozinha: 100 - txBruta,
        taxa_descontos: !!txDesconto ? removeMaskDinheiro(txDesconto) : 0,
        taxa_reajuste_meta: 0,
      };

      await apiPost(`config`, data);
      toast.success("Registro editado.");
    } catch (e) {
      catchError(e);
    } finally {
      setLoad(false);
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        {loadContainer ? (
          <div
            style={{
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              marginTop: 40,
              marginBottom: 60,
            }}
          >
            <ReactLoading
              type={"spin"}
              color={theme.palette.secondary}
              height={"32px"}
              width={"32px"}
            />
          </div>
        ) : (
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <MDTypography variant="h6" color="white">
                    Editar Configurações
                  </MDTypography>

                  <MDButton variant="gradient" color="dark" onClick={salvar}>
                    {load ? "Salvando" : "Salvar"}
                  </MDButton>
                </MDBox>

                <MDBox p={3}>
                  <MDTypography
                    variant="body2"
                    fontWeight="regular"
                    color="dark"
                  >
                    Taxas (Comissão):
                  </MDTypography>
                  <MDBox
                    mb={2}
                    width={"100%"}
                    display="flex"
                    style={{
                      width: "100%",
                      gap: 16,
                    }}
                  >
                    <MDTypography variant="body2" color="dark">
                      Vendedor ({txBruta}%)
                    </MDTypography>
                    <Slider
                      aria-label="Taxas"
                      defaultValue={40}
                      valueLabelDisplay="auto"
                      value={txBruta}
                      onChange={(v) => setTxBruta(v.target.value)}
                      step={1}
                      color="primary"
                      marks
                      min={1}
                      max={99}
                    />

                    <MDTypography variant="body2" color="dark">
                      Restante ({100 - txBruta}%)
                    </MDTypography>
                  </MDBox>

                  <MDBox
                    mb={2}
                    width={"100%"}
                    display="flex"
                    style={{ width: "100%", maxWidth: 300, marginTop: 30 }}
                    maxlength="5"
                  >
                    <MDInput
                      label="Taxa de descontos extras (%)"
                      fullWidth
                      value={txDesconto}
                      onChange={(e) =>
                        setTxDesconto(setMaskRunTimeDinheiro(e.target.value))
                      }
                    />
                  </MDBox>

                  <Divider />

                  {/*<MDTypography
                    variant="body2"
                    fontWeight="regular"
                    color="dark"
                  >
                    Ordem dos vendedores:
                    </MDTypography>*/}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        )}
      </MDBox>
    </DashboardLayout>
  );
}
