/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import ReactLoading from "react-loading";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import MDButton from "components/MDButton";
import theme from "assets/theme";
import React from "react";
import { catchError } from "service/api";
import { apiGet } from "service/api";
import MDBadge from "components/MDBadge";
import { apiDelete } from "service/api";
import { toast } from "react-toastify";
import { Icon } from "@mui/material";
import { DarkMode } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export function Colaborador() {
  const navigate = useNavigate();

  const [load, setLoad] = React.useState(true);
  const [loadExclusao, setLoadExclusao] = React.useState(null);
  const [data, setData] = React.useState([]);

  const colunas = [
    { Header: "nome", accessor: "nome", width: "30%", align: "left" },
    { Header: "nível", accessor: "nivel", align: "left" },
    { Header: "vendedor", accessor: "vendedor", align: "center" },
    { Header: "acesso", accessor: "acesso", align: "center" },
    { Header: "ações", accessor: "acao", align: "center" },
  ];

  async function getData() {
    try {
      setLoad(true);
      const response = await apiGet("/collaborator");
      setData(response);
    } catch (e) {
      catchError(e);
    } finally {
      setLoad(false);
    }
  }

  React.useEffect(() => {
    getData();
  }, []);

  async function excluir(item) {
    if (!window.confirm("Deseja excluír esse registro?")) {
      return;
    }

    try {
      setLoadExclusao(item.id);
      await apiDelete(`/collaborator/${item.id}`);
      toast.success("Registro excluído com sucesso.");
      getData();
    } catch (e) {
      catchError(e);
    } finally {
      setLoadExclusao(null);
    }
  }

  function renderItem(item) {
    const colorNivel = item.nivel == "A" ? "success" : "warning";
    const textNivel = item.nivel == "A" ? "Administrador" : "Outros";

    return {
      nome: (
        <MDTypography display="block" variant="button" fontWeight="medium">
          {item.nome}
        </MDTypography>
      ),
      nivel: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent={textNivel}
            color={colorNivel}
            variant="gradient"
            size="sm"
          />
        </MDBox>
      ),
      vendedor: (
        <MDTypography
          component="h1"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {!!item.e_garcon ? "Sim" : "Não"}
        </MDTypography>
      ),
      acesso: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {!!item.acesso && !!item.senha ? "Tem acesso" : "Não tem"}
        </MDTypography>
      ),
      acao: (
        <MDBox display="flex" alignItems="center">
          <MDButton
            onClick={() => navigate("/colaborador/editar", { state: item })}
            variant="text"
            color={DarkMode ? "dark" : "white"}
          >
            <Icon>edit</Icon>&nbsp;Editar
          </MDButton>
          <MDBox mr={1}>
            <MDButton
              onClick={() => excluir(item)}
              variant="text"
              color="error"
            >
              <Icon>delete</Icon>&nbsp;{" "}
              {loadExclusao == item.id ? "Excluíndo" : "Excluír"}
            </MDButton>
          </MDBox>
        </MDBox>
      ),
    };
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Meus Colaboradores
                </MDTypography>

                <MDButton
                  variant="gradient"
                  color="dark"
                  onClick={() => navigate("/colaborador/cadastro")}
                >
                  Cadastrar
                </MDButton>
              </MDBox>
              <MDBox pt={3}>
                {load ? (
                  <div
                    style={{
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      marginTop: 40,
                      marginBottom: 60,
                    }}
                  >
                    <ReactLoading
                      type={"spin"}
                      color={theme.palette.secondary}
                      height={"32px"}
                      width={"32px"}
                    />
                  </div>
                ) : (
                  <>
                    {data.length == 0 ? (
                      <div
                        style={{
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          marginTop: 40,
                          marginBottom: 60,
                        }}
                      >
                        <MDTypography
                          component="h1"
                          variant="body2"
                          color="text"
                          fontWeight="medium"
                        >
                          Nenhum conteúdo encontrado.
                        </MDTypography>
                      </div>
                    ) : (
                      <DataTable
                        table={{
                          columns: colunas,
                          rows: data.map((item) => renderItem(item)),
                        }}
                        isSorted={false}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                      />
                    )}
                  </>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
