/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import ReactLoading from "react-loading";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import MDButton from "components/MDButton";
import theme from "assets/theme";
import React from "react";
import { catchError } from "service/api";
import { apiGet } from "service/api";
import MDBadge from "components/MDBadge";
import { apiDelete } from "service/api";
import { toast } from "react-toastify";
import { FormControl, Icon, InputLabel, MenuItem, Select } from "@mui/material";
import { DarkMode } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";

import { format, parseISO, subYears, addYears } from "date-fns";
import { useState } from "react";
import {
  ContaienrProgresso,
  ContainerDivisoDesejado,
  ContainerDivisor,
  ContainerGraficoProgresso,
  ContainerInfo,
  ContainerProgressoSimples,
  ContainerValorDesejado,
  ContainerValorMinimo,
  ProgressoSimples,
  SeparadorDesejado,
  SeparadorMinimo,
  WrapperMeuProgresso,
  WrapperProgresso,
} from "./styles";
import { setMaskRunTimeDinheiro } from "layouts/helpers/mascaraDinheiro";
import { setMaskPositiveOrNegativeDinheiro } from "layouts/helpers/mascaraDinheiro";

export function Resultados() {
  const navigate = useNavigate();
  const location = useLocation();

  const [load, setLoad] = React.useState(true);
  const [data, setData] = React.useState([]);

  const colunas = [
    { Header: "Data", accessor: "data", align: "center" },
    { Header: "Venda", accessor: "venda", align: "center" },
    { Header: "Média Fixa", accessor: "media", align: "center" },
    { Header: "Diferença", accessor: "diferenca", align: "center" },
    { Header: "Venda Projetada", accessor: "projetada", align: "center" },
    { Header: "Venda Acumulada", accessor: "vendaacumulada", align: "center" },
    {
      Header: "Acumulo Projetado",
      accessor: "acumulo",
      align: "center",
    },
  ];

  async function getData() {
    try {
      setLoad(true);
      const response = await apiGet(`objective/${location.state?.id}/results`);
      setData(response);
    } catch (e) {
      catchError(e);
    } finally {
      setLoad(false);
    }
  }

  React.useEffect(() => {
    getData();
  }, []);

  function renderItem(item) {
    return {
      data: (
        <MDTypography
          style={{
            color:
              item.info === "Falta"
                ? "#e74c3c"
                : item.info === "Folga"
                ? "#3498db"
                : "#3e3e3e",
          }}
          display="block"
          variant="button"
          fontWeight="medium"
        >
          {format(parseISO(item.data), "dd/MM/yyyy")}
        </MDTypography>
      ),
      venda: (
        <MDTypography
          style={{
            color:
              item.info === "Falta"
                ? "#e74c3c"
                : item.info === "Folga"
                ? "#3498db"
                : "#3e3e3e",
          }}
          display="block"
          variant="button"
          fontWeight="medium"
        >
          {!!item.info
            ? item.info
            : item.venda.real != null && item.venda.real != ""
            ? setMaskRunTimeDinheiro(parseFloat(item.venda.real).toFixed(2))
            : "-"}
        </MDTypography>
      ),
      media: (
        <MDTypography
          style={{
            color:
              item.info === "Falta"
                ? "#e74c3c"
                : item.info === "Folga"
                ? "#3498db"
                : "#3e3e3e",
          }}
          display="block"
          variant="button"
          fontWeight="medium"
        >
          {!!item.info
            ? item.info
            : item.venda.projetada
            ? setMaskRunTimeDinheiro(
                parseFloat(item.venda.projetada).toFixed(2)
              )
            : "-"}
        </MDTypography>
      ),
      diferenca: (
        <MDTypography
          style={{
            color:
              item.info === "Falta"
                ? "#e74c3c"
                : item.info === "Folga"
                ? "#3498db"
                : item.venda.diferenca <= 0
                ? "#e74c3c"
                : "#27ae60",
          }}
          display="block"
          variant="button"
          fontWeight="medium"
        >
          {!!item.info
            ? item.info
            : item.venda.diferenca != null && item.venda.diferenca != ""
            ? setMaskPositiveOrNegativeDinheiro(
                parseFloat(item.venda.diferenca).toFixed(2)
              )
            : "-"}
        </MDTypography>
      ),
      projetada: (
        <MDTypography
          style={{
            color:
              item.info === "Falta"
                ? "#e74c3c"
                : item.info === "Folga"
                ? "#3498db"
                : "#3e3e3e",
          }}
          display="block"
          variant="button"
          fontWeight="medium"
        >
          {!!item.info
            ? item.info
            : item.planejamento_futuro
            ? setMaskRunTimeDinheiro(
                parseFloat(item.planejamento_futuro).toFixed(2)
              )
            : "-"}
        </MDTypography>
      ),
      vendaacumulada: (
        <MDTypography
          style={{
            color:
              item.info === "Falta"
                ? "#e74c3c"
                : item.info === "Folga"
                ? "#3498db"
                : "#3e3e3e",
          }}
          display="block"
          variant="button"
          fontWeight="medium"
        >
          {!!item.info
            ? item.info
            : item.acumulado.real
            ? setMaskRunTimeDinheiro(parseFloat(item.acumulado.real).toFixed(2))
            : "-"}
        </MDTypography>
      ),
      acumulo: (
        <MDTypography
          style={{
            color:
              item.info === "Falta"
                ? "#e74c3c"
                : item.info === "Folga"
                ? "#3498db"
                : "#3e3e3e",
          }}
          display="block"
          variant="button"
          fontWeight="medium"
        >
          {!!item.info
            ? item.info
            : item.acumulado.projetada
            ? setMaskRunTimeDinheiro(
                parseFloat(item.acumulado.projetada).toFixed(2)
              )
            : "-"}
        </MDTypography>
      ),
    };
  }

  function tratamentoMascara(data) {
    if (data) {
      return format(parseISO(data), "dd/MM/yyyy");
    }

    return "";
  }

  const [show, setShow] = useState(null);

  function calcProgressoMinimo(valorMinino, valorDesejado) {
    if (valorMinino <= 0 || valorDesejado <= 0) {
      return 0;
    }

    return parseInt(parseFloat((valorMinino * 100) / valorDesejado).toFixed(2));
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDButton
        variant="gradient"
        color="light"
        onClick={() => navigate("/meta")}
      >
        Voltar
      </MDButton>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="flex-start"
                justifyContent="center"
                flexDirection="column"
              >
                <MDTypography variant="h4" color="white">
                  Meta: {data?.nome}
                </MDTypography>
                <MDTypography variant="h6" color="white">
                  Período: {tratamentoMascara(data?.dt_inicio)} à{" "}
                  {tratamentoMascara(data?.dt_final)}
                </MDTypography>
                {data?.expirada ? (
                  <MDTypography variant="h6" color="white">
                    Meta encerrada.
                  </MDTypography>
                ) : (
                  <MDTypography variant="h6" color="white">
                    {data?.falta_dias} para encerrar a meta.
                  </MDTypography>
                )}
              </MDBox>
              <MDBox pt={3}>
                {load ? (
                  <div
                    style={{
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      marginTop: 40,
                      marginBottom: 60,
                    }}
                  >
                    <ReactLoading
                      type={"spin"}
                      color={theme.palette.secondary}
                      height={"32px"}
                      width={"32px"}
                    />
                  </div>
                ) : (
                  <>
                    {data?.meta_colaboradores?.length == 0 ? (
                      <div
                        style={{
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          marginTop: 40,
                          marginBottom: 60,
                        }}
                      >
                        <MDTypography
                          component="h1"
                          variant="body2"
                          color="text"
                          fontWeight="medium"
                        >
                          Nenhum conteúdo encontrado.
                        </MDTypography>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {!load && (
        <>
          {data?.meta_colaboradores?.map((meta) => (
            <MDBox key={meta.id} pt={0} pb={2}>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Card>
                    <MDBox
                      px={2}
                      py={2}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <MDTypography variant="h5" color={"dark"}>
                        {meta.colaborador.nome}
                      </MDTypography>
                      <MDBox
                        style={{ cursor: "pointer" }}
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                        onClick={() =>
                          show === meta.id ? setShow(null) : setShow(meta.id)
                        }
                      >
                        {data?.expirada ? (
                          <MDTypography
                            style={{ marginRight: 20, color: meta.corStatus }}
                            variant="h6"
                            color={meta.corStatus}
                          >
                            {meta.status}!
                          </MDTypography>
                        ) : (
                          <ContainerProgressoSimples>
                            <ProgressoSimples
                              progresso={parseInt(meta.progresso)}
                              cor={
                                meta.total_vendido < meta.valor_minimo
                                  ? "#e74c3c"
                                  : meta.total_vendido >= meta.valor_desejado
                                  ? "#27ae60"
                                  : "#e67e22"
                              }
                            />

                            <span>
                              {setMaskRunTimeDinheiro(
                                parseFloat(meta.progresso).toFixed(2)
                              )}
                              %
                            </span>
                          </ContainerProgressoSimples>
                        )}

                        {show == meta.id ? (
                          <Icon>keyboard_arrow_down</Icon>
                        ) : (
                          <Icon>keyboard_arrow_right</Icon>
                        )}
                      </MDBox>
                    </MDBox>

                    {show == meta.id && (
                      <MDBox
                        px={2}
                        py={0}
                        display="flex"
                        alignItems="flex-start"
                        justifyContent="flex-start"
                        flexDirection="column"
                      >
                        <ContainerInfo>
                          <div
                            style={{
                              width: "100%",
                              height: 1,
                              background: "#3e3e3e",
                              marginBottom: 20,
                            }}
                          ></div>

                          <ContaienrProgresso>
                            <ContainerValorMinimo>
                              <ContainerDivisor
                                progresso={calcProgressoMinimo(
                                  meta.valor_minimo,
                                  meta.valor_desejado
                                )}
                              >
                                <span>
                                  Mínimo de R$&nbsp;
                                  {setMaskRunTimeDinheiro(
                                    parseFloat(meta.valor_minimo).toFixed(2)
                                  )}
                                </span>
                                <SeparadorMinimo />
                              </ContainerDivisor>
                            </ContainerValorMinimo>
                          </ContaienrProgresso>

                          <ContainerGraficoProgresso>
                            <WrapperProgresso>
                              <span>
                                {setMaskRunTimeDinheiro(
                                  parseFloat(meta.progresso).toFixed(2)
                                )}
                                %
                              </span>

                              <WrapperMeuProgresso
                                progresso={parseInt(meta.progresso)}
                                cor={
                                  meta.total_vendido < meta.valor_minimo
                                    ? "#e74c3c"
                                    : meta.total_vendido >= meta.valor_desejado
                                    ? "#27ae60"
                                    : "#e67e22"
                                }
                              />
                            </WrapperProgresso>
                          </ContainerGraficoProgresso>

                          <ContainerValorDesejado>
                            <ContainerDivisoDesejado>
                              <SeparadorDesejado />
                              <span>
                                A meta é R$&nbsp;
                                {setMaskRunTimeDinheiro(
                                  parseFloat(meta.valor_desejado).toFixed(2)
                                )}
                              </span>
                            </ContainerDivisoDesejado>
                          </ContainerValorDesejado>
                        </ContainerInfo>

                        <MDTypography variant="h6" color="dark">
                          Total de vendas: R${" "}
                          {setMaskRunTimeDinheiro(
                            parseFloat(meta.total_vendido).toFixed(2)
                          )}
                        </MDTypography>
                        <MDTypography variant="h6" color="dark">
                          Total de comissão: R${" "}
                          {setMaskRunTimeDinheiro(
                            parseFloat(meta.total_comissao).toFixed(2)
                          )}
                        </MDTypography>
                        <MDTypography
                          style={{ marginBottom: 20 }}
                          variant="h6"
                          color="dark"
                        >
                          Quanto falta para bater a meta: R${" "}
                          {setMaskRunTimeDinheiro(
                            parseFloat(meta.falta).toFixed(2)
                          )}
                        </MDTypography>

                        <div
                          style={{
                            width: "100%",
                            height: 1,
                            background: "#3e3e3e",
                            marginBottom: 20,
                          }}
                        ></div>

                        <DataTable
                          table={{
                            columns: colunas,
                            rows: meta.resultados.map((item) =>
                              renderItem(item)
                            ),
                          }}
                          isSorted={false}
                          entriesPerPage={false}
                          showTotalEntries={false}
                          noEndBorder
                        />
                      </MDBox>
                    )}
                  </Card>
                </Grid>
              </Grid>
            </MDBox>
          ))}
        </>
      )}
    </DashboardLayout>
  );
}
