/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import ReactLoading from "react-loading";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import MDButton from "components/MDButton";
import theme from "assets/theme";
import React from "react";
import { catchError } from "service/api";
import { apiGet } from "service/api";
import MDBadge from "components/MDBadge";
import { apiDelete } from "service/api";
import { toast } from "react-toastify";
import { FormControl, Icon, InputLabel, MenuItem, Select } from "@mui/material";
import { DarkMode } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import { format, parseISO, subYears, addYears } from "date-fns";

export function Meta() {
  const navigate = useNavigate();

  const [load, setLoad] = React.useState(true);
  const [loadExclusao, setLoadExclusao] = React.useState(null);
  const [data, setData] = React.useState([]);

  const filtrosAno = React.useMemo(() => {
    let dataAtual = format(new Date(), "yyyy");

    dataAtual = parseInt(dataAtual);
    dataAtual -= 5;

    const aux = [];

    for (let i = 0; i <= 10; i++) {
      aux.push(dataAtual);
      dataAtual = dataAtual + 1;
    }

    return aux;
  }, []);

  const [dataFilter, setDataFilter] = React.useState(
    parseInt(format(new Date(), "yyyy"))
  );

  const colunas = [
    { Header: "nome", accessor: "nome", width: "30%", align: "left" },
    { Header: "Data Inicial", accessor: "inicio", align: "left" },
    { Header: "Data Final", accessor: "fim", align: "center" },
    { Header: "ações", accessor: "acao", align: "center" },
  ];

  async function getData() {
    try {
      setLoad(true);
      const response = await apiGet(`/objective?filterYear=${dataFilter}`);
      setData(response);
    } catch (e) {
      catchError(e);
    } finally {
      setLoad(false);
    }
  }

  React.useEffect(() => {
    getData();
  }, []);

  async function excluir(item) {
    if (!window.confirm("Deseja excluír esse registro?")) {
      return;
    }

    try {
      setLoadExclusao(item.id);
      await apiDelete(`/objective/${item.id}`);
      toast.success("Registro excluído com sucesso.");
      getData();
    } catch (e) {
      catchError(e);
    } finally {
      setLoadExclusao(null);
    }
  }

  function renderItem(item) {
    return {
      nome: (
        <MDTypography display="block" variant="button" fontWeight="medium">
          {item.nome}
        </MDTypography>
      ),
      inicio: (
        <MDTypography
          component="h1"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {format(parseISO(item.dt_inicio), "dd/MM/yyyy")}
        </MDTypography>
      ),
      fim: (
        <MDTypography
          component="h1"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {format(parseISO(item.dt_final), "dd/MM/yyyy")}
        </MDTypography>
      ),
      acao: (
        <MDBox display="flex" alignItems="center">
          <MDButton
            onClick={() => navigate("/meta/resultados", { state: item })}
            variant="text"
            color={"warning"}
          >
            <Icon>equalizer</Icon>&nbsp;Resultados
          </MDButton>
          <MDButton
            onClick={() => navigate("/meta/editar", { state: item })}
            variant="text"
            color={DarkMode ? "dark" : "white"}
          >
            <Icon>edit</Icon>&nbsp;Editar
          </MDButton>
          <MDBox mr={1}>
            <MDButton
              onClick={() => excluir(item)}
              variant="text"
              color="error"
            >
              <Icon>delete</Icon>&nbsp;{" "}
              {loadExclusao == item.id ? "Excluíndo" : "Excluír"}
            </MDButton>
          </MDBox>
        </MDBox>
      ),
    };
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Meta
                </MDTypography>

                <MDButton
                  variant="gradient"
                  color="dark"
                  onClick={() => navigate("/meta/cadastro")}
                >
                  Cadastrar
                </MDButton>
              </MDBox>
              <MDBox pt={3}>
                <div
                  style={{
                    width: "100%",
                    paddingLeft: 18,
                    paddingRight: 18,
                    paddingBottom: 20,
                    gap: 16,
                    display: "flex",
                  }}
                >
                  <FormControl style={{ width: "50%", maxWidth: 200 }}>
                    <InputLabel id="demo-simple-select-label">
                      Filtro (Ano)
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Filtro (Ano)"
                      size="medium"
                      style={{ height: 40, width: "100%" }}
                      value={dataFilter}
                      onChange={(e) => setDataFilter(e.target.value)}
                    >
                      {filtrosAno.map((ano) => (
                        <MenuItem value={ano}>{ano}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <MDButton
                    variant="gradient"
                    color="success"
                    onClick={getData}
                  >
                    Aplicar
                  </MDButton>
                </div>

                {load ? (
                  <div
                    style={{
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      marginTop: 40,
                      marginBottom: 60,
                    }}
                  >
                    <ReactLoading
                      type={"spin"}
                      color={theme.palette.secondary}
                      height={"32px"}
                      width={"32px"}
                    />
                  </div>
                ) : (
                  <>
                    {data.length == 0 ? (
                      <div
                        style={{
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          marginTop: 40,
                          marginBottom: 60,
                        }}
                      >
                        <MDTypography
                          component="h1"
                          variant="body2"
                          color="text"
                          fontWeight="medium"
                        >
                          Nenhum conteúdo encontrado.
                        </MDTypography>
                      </div>
                    ) : (
                      <DataTable
                        table={{
                          columns: colunas,
                          rows: data.map((item) => renderItem(item)),
                        }}
                        isSorted={false}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                      />
                    )}
                  </>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
