/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";
import React from "react";
import { AuthContext } from "context/AuthContext";
import { Navigate } from "react-router-dom";
import { Colaborador } from "layouts/features/Colaborador";
import { ColaboradorCadastro } from "layouts/features/Colaborador/Cadastro";
import { Meta } from "layouts/features/Meta";
import { MetaCadastro } from "layouts/features/Meta/Cadastro";
import { ConfigGeral } from "layouts/features/Config/Cadastro";
import { Lancamento } from "layouts/features/Lancamento";
import { Presenca } from "layouts/features/Presenca";
import { Resultados } from "layouts/features/Meta/Resultados";
import { Relatorio } from "layouts/features/Relatorio";
import { ResultadosColaborador } from "layouts/features/Meta/ResultadosColaborador";

const routes = [
  {
    type: "none",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/login",
    component: <SignIn />,
    privado: false,
    verificarAdmin: false,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
    privado: true,
    verificarAdmin: false,
  },
  {
    type: "collapse",
    name: "Colaborador",
    key: "colaborador",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/colaborador",
    component: <Colaborador />,
    privado: true,
    verificarAdmin: true,
  },
  {
    type: "none",
    name: "Cadastrar Colaborador",
    key: "colaborador-register",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/colaborador/cadastro",
    component: <ColaboradorCadastro />,
    privado: true,
    verificarAdmin: true,
  },
  {
    type: "none",
    name: "Editar Colaborador",
    key: "colaborador-update",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/colaborador/editar",
    component: <ColaboradorCadastro />,
    privado: true,
    verificarAdmin: true,
  },

  {
    type: "collapse",
    name: "Meta",
    key: "meta",
    icon: <Icon fontSize="small">gps_fixed</Icon>,
    route: "/meta",
    component: <Meta />,
    privado: true,
    verificarAdmin: true,
  },
  {
    type: "none",
    name: "Cadastrar Meta",
    key: "meta-register",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/meta/cadastro",
    component: <MetaCadastro />,
    privado: true,
    verificarAdmin: true,
  },
  {
    type: "none",
    name: "Editar Meta",
    key: "meta-update",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/meta/editar",
    component: <MetaCadastro />,
    privado: true,
    verificarAdmin: true,
  },
  {
    type: "none",
    name: "Resultados da Meta",
    key: "meta-result",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/meta/resultados",
    component: <Resultados />,
    privado: true,
    verificarAdmin: true,
  },
  {
    type: "none",
    name: "Minha Meta",
    key: "minha-meta",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/minha-meta",
    component: <ResultadosColaborador />,
    privado: true,
    verificarAdmin: false,
  },
  {
    type: "collapse",
    name: "Lançamentos",
    key: "lancamentos",
    icon: <Icon fontSize="small">monetization_on</Icon>,
    route: "/lancamentos",
    component: <Lancamento />,
    privado: true,
    verificarAdmin: true,
  },
  {
    type: "collapse",
    name: "Presença",
    key: "presenca",
    icon: <Icon fontSize="small">grading</Icon>,
    route: "/presenca",
    component: <Presenca />,
    privado: true,
    verificarAdmin: true,
  },
  {
    type: "collapse",
    name: "Relatório",
    key: "relatorio",
    icon: <Icon fontSize="small">article</Icon>,
    route: "/relatorio",
    component: <Relatorio />,
    privado: true,
    verificarAdmin: true,
  },
  {
    type: "collapse",
    name: "Configurações",
    key: "configuracoes",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/configuracoes",
    component: <ConfigGeral />,
    privado: true,
    verificarAdmin: true,
  },
];

export default routes;
