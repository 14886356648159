import geradorDocumentoPDF from "geradorDocumentoPDF";
import { setLine } from "geradorDocumentoPDF";
import { setMaskRunTimeDinheiro } from "layouts/helpers/mascaraDinheiro";

//setMaskRunTimeDinheiro(parseFloat(row.valor).toFixed(2)),

export async function gerarFaltasRelatorio(faltas, setUrl = () => null) {
  const content = [];

  content.push(setLine("Período: ", faltas.periodo, "#000"));

  content.push({
    text: "\n\n",
    bold: true,
  });

  // /* CRIANDO TABELA */
  const auxBodyEntradas = [];

  faltas.data.map((row) => {
    auxBodyEntradas.push([row.nome, row.quantidade]);
  });

  content.push({
    // layout: "lightHorizontalLines", // optional
    table: {
      headerRows: 1,
      widths: ["*", "*"],
      body: [
        [
          { text: "Nome", bold: true },
          { text: "Quantidade de Faltas", bold: true },
        ],
        ...auxBodyEntradas,
      ],
    },
  });

  await geradorDocumentoPDF(
    content,
    "Relatório de faltas",
    "Faltas de um período",
    setUrl
  );
}

export async function gerarComissaRelatorio(comissao, setUrl = () => null) {
  const content = [];

  content.push(setLine("Período: ", comissao.periodo, "#000"));

  content.push({
    text: "\n\n",
    bold: true,
  });

  content.push(
    setLine(
      "Total arrecadado em comissão:",
      "R$ " +
        setMaskRunTimeDinheiro(parseFloat(comissao.comissao_total).toFixed(2)),
      "#000"
    )
  );
  content.push({
    text: "\n",
    bold: true,
  });

  content.push(
    setLine(
      "Total para vendedores:",
      "R$ " +
        setMaskRunTimeDinheiro(parseFloat(comissao.comissao_40).toFixed(2)),
      "#000"
    )
  );
  content.push({
    text: "\n",
    bold: true,
  });

  content.push(
    setLine(
      "Total para os demais:",
      "R$ " +
        setMaskRunTimeDinheiro(parseFloat(comissao.comissao_60).toFixed(2)),
      "#000"
    )
  );
  content.push({
    text: "\n",
    bold: true,
  });

  content.push(
    setLine(
      "Total em descontos:",
      "R$ " + setMaskRunTimeDinheiro(parseFloat(comissao.descontos).toFixed(2)),
      "#000"
    )
  );
  content.push({
    text: "\n",
    bold: true,
  });

  content.push({
    text: "\n",
    bold: true,
  });

  content.push(setLine("Comissão (Garçom)", "", "#000", "center"));

  // /* CRIANDO TABELA */
  const auxBodyEntradas = [];

  [...comissao.garcon].map((row) => {
    auxBodyEntradas.push([
      row.nome,
      "R$ " + setMaskRunTimeDinheiro(parseFloat(row.total).toFixed(2)),
    ]);
  });

  content.push({
    // layout: "lightHorizontalLines", // optional
    table: {
      headerRows: 1,
      widths: ["*", "*"],
      body: [
        [
          { text: "Nome", bold: true },
          { text: "Total", bold: true },
        ],
        ...auxBodyEntradas,
      ],
    },
  });

  /* Outros */
  content.push({
    text: "\n",
    bold: true,
  });
  content.push({
    text: "\n",
    bold: true,
  });

  content.push(setLine("Comissão (Compartilhada)", "", "#000", "center"));

  // /* CRIANDO TABELA */
  const auxBodyCompartilhada = [];

  [...comissao.outros].map((row) => {
    auxBodyCompartilhada.push([
      row.nome,
      "R$ " + setMaskRunTimeDinheiro(parseFloat(row.total).toFixed(2)),
    ]);
  });

  content.push({
    // layout: "lightHorizontalLines", // optional
    table: {
      headerRows: 1,
      widths: ["*", "*"],
      body: [
        [
          { text: "Nome", bold: true },
          { text: "Total", bold: true },
        ],
        ...auxBodyCompartilhada,
      ],
    },
  });

  await geradorDocumentoPDF(
    content,
    "Relatório de comissão",
    "Comissão de um período",
    setUrl
  );
}

export async function gerarMetaRelatorio(meta, setUrl = () => null) {
  const content = [];

  content.push(setLine("Nome da Meta:", meta.nome_meta, "#000"));
  content.push({
    text: "\n",
    bold: true,
  });

  content.push(setLine("Período: ", meta.periodo, "#000"));

  content.push({
    text: "\n",
    bold: true,
  });

  content.push({
    text: "\n",
    bold: true,
  });

  // /* CRIANDO TABELA */
  const auxBodyEntradas = [];

  function compareName(a, b) {
    var nameA = a.nome.toLowerCase(),
      nameB = b.nome.toLowerCase();
    if (nameA < nameB)
      //sort string ascending
      return -1;
    if (nameA > nameB) return 1;
    return 0; //default return value (no sorting)
  }

  meta.colaboradores.sort(compareName).map((row) => {
    auxBodyEntradas.push([
      row.nome,
      "R$ " + setMaskRunTimeDinheiro(parseFloat(row.valor_desejado).toFixed(2)),
      "R$ " + setMaskRunTimeDinheiro(parseFloat(row.valor_minimo).toFixed(2)),
      "R$ " + setMaskRunTimeDinheiro(parseFloat(row.total_vendido).toFixed(2)),
    ]);
  });

  content.push({
    // layout: "lightHorizontalLines", // optional
    table: {
      headerRows: 1,
      widths: ["*", "*", "*", "*"],
      body: [
        [
          { text: "Vendedor", bold: true },
          { text: "Valor Meta", bold: true },
          { text: "Valor Mínimo", bold: true },
          { text: "Total vendido", bold: true },
        ],
        ...auxBodyEntradas,
      ],
    },
  });

  await geradorDocumentoPDF(content, "Relatório de meta", "", setUrl);
}
